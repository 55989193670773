<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <b-card
            header="Mani pasākumu pieteikumi"
            header-tag="header"
            footer-tag="footer"
            footer=" "
        >
          <b-button variant="success" class="float-right" :to="{ name: 'EventsCreate' }">Pievienot</b-button>

          <b-row>
            <b-col lg="9" class="my-1">
              <b-form-checkbox-group
                  id="checkbox-group-1"
                  v-model="filterInput"
                  :options="filter_options"
                  name="flavour-1"
              ></b-form-checkbox-group>
            </b-col>
          </b-row>

          <b-table striped hover responsive="true" small v-b-hover fixed
                   ref="table"
                   :items="events"
                   :fields="fields"
                   :no-provider-sorting="true"
                   v-if="events">

            <template #cell(date_from)="row">
              <span> {{ dateFormat(row.item.date_from, "dd.mm.yyyy") }} {{ row.item.time_from.substring(0, 5) }} </span>
            </template>

            <template #cell(date_to)="row">
              <span> {{ dateFormat(row.item.date_to, "dd.mm.yyyy") }} {{ row.item.time_to.substring(0, 5) }}</span>
            </template>

            <template #cell(status_it)="row">
              <b-icon v-if="row.item.status_it === 1" icon="check-circle-fill" variant="success"></b-icon>
              <b-icon v-else icon="check-circle-fill" variant="danger"></b-icon>
            </template>

            <template #cell(status_as)="row">
              <b-icon v-if="row.item.status_as === 1" icon="check-circle-fill" variant="success"></b-icon>
              <b-icon v-else icon="check-circle-fill" variant="danger"></b-icon>
            </template>

            <template #cell(status_sa)="row">
              <b-icon v-if="row.item.status_sa === 1" icon="check-circle-fill" variant="success"></b-icon>
              <b-icon v-else icon="check-circle-fill" variant="danger"></b-icon>
            </template>

            <template #cell(status_md)="row">
              <b-icon v-if="row.item.status_md === 1" icon="check-circle-fill" variant="success"></b-icon>
              <b-icon v-else icon="check-circle-fill" variant="danger"></b-icon>
            </template>

            <template #cell(actions)="row">
              <b-button size="sm" variant="warning" class="ml-1" @click="$router.push({ name: 'EventsView', params: {id: row.item.id} })">
                Skatīt
              </b-button>
            </template>

          </b-table>
          <template v-if="isBusy && !noData">
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Dati tiek lejupielādēti...</strong>
            </div>
          </template>

        </b-card>
      </b-col>
    </b-row>

    <b-modal ref="my-modal" hide-footer :title="event_status ? 'Apstiprināt pasākumu' : 'Noraidīt pasākumu'">
      <div class="d-block text-center">
        <b-form @submit.prevent="onSubmit">
          <b-form-textarea
              placeholder="Komentārs"
              v-model="event_comment"
              :required="!Boolean(event_status)"
          >

          </b-form-textarea>

          <b-button class="mt-2" type="submit" block :variant="event_status ? 'success' : 'danger'">{{ event_status ? 'Apstiprināt' : 'Noraidīt' }}</b-button>
        </b-form>
      </div>
      <b-button class="mt-3" variant="warning" block @click="hideModal">Aizvērt</b-button>
    </b-modal>

  </b-container>
</template>

<script>
const dateFormat = require('dateformat');

export default {
  name: "List",
  data() {
    return {
      dateFormat: dateFormat,
      fields: [
        { key: 'name', label: 'Nosaukums' },
        { key: 'date_from', label: 'Datums no' },
        { key: 'date_to', label: 'Datums līdz' },
        { key: 'place_id', label: 'Vieta' },
        { key: 'count', label: 'Dalībnieku skaits' },
        { key: 'user_id', label: 'Atbildīgais' },
        { key: 'status_it', label: 'IT' },
        { key: 'status_as', label: 'Adm.sekt.' },
        { key: 'status_md', label: 'Māc.daļ.' },
        { key: 'status_sa', label: 'Sab. att.' },
        { key: 'actions', label: 'Darbības' },
      ],
      events: [],
      loaded: false,
      isBusy: false,
      noData: false,
      currentPage: 0,
      event_id: null,
      event_comment: null,
      event_status: null,
      filtered: [],
      filter_options: [
        { text: 'Apstiprinātie', value: '1' },
        { text: 'Noliegtie', value: '0' },
        { text: 'Daļēji', value: 'partly' },
        { text: 'Pārējie', value: 'others' },
      ]
    }
  },
  computed: {
    filterInput: {
      get() {
        return this.filtered;
      },
      set(value) {
        this.filtered = value;
        this.onFilter();
      }
    },
  },
  mounted() {
    this.getData();
    window.onscroll = () => {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;

      if (bottomOfWindow && !this.isBusy) {
        this.getData();
      }
    };
  },
  methods: {
    showModal(id, status) {
      this.event_id = id;
      this.event_status = status;

      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    onFilter() {
      this.currentPage = 0;
      this.events = [];

      this.getData();
    },
    onSubmit(event) {
      event.preventDefault()

      let data = {
        'status': this.event_status,
        'comment': this.event_comment,
      }

      axios.put('/events/'+this.event_id, data).then(response => {
        this.hideModal();
        this.events = [];
        this.getData();
      });

    },
    async getData() {
      this.isBusy = true;
      await axios.get('/events?page[number]=' + this.currentPage + `&filter[status]=${this.filtered}&filter[my]=true`).then(response => {

        let data = response.data.data;

        if(data.length) {
          this.events = this.events.concat(data);
          this.noData = false;
          this.isBusy = false;
        } else {
          this.noData = true;
        }
        console.log(this.events);
      }).catch(e => {
        this.isBusy = false;
      })

      this.currentPage++;
    },
  },
}
</script>

<style scoped>

</style>